import * as React from 'react';
import {cloneDeep, filter, debounce, times, uniqBy} from 'lodash-es';
import {
  Box,
  Button,
  Cluster,
  ISimpleAdPod,
  ISimpleEntry,
  Spinner,
  Stack,
  TSize,
  generateRandom,
} from '@pluto-tv/assemble';
import {IPlayerSubtitle, Player, IPlayerRef, IPlayerPoint} from '@pluto-tv/assemble-player';

import {IClip} from 'models/clips';
import {IEpisodeSource, ISourceAdPod} from 'models/episodes';

import {IClipVideoData, getClipId, getClipVideo} from 'helpers/useGetClipVideo';

export interface IPlaylistPlayerRef {
  play(): void;
  pause(): void;
  playPause(): void;
  getCurrentTime(): [number, number?];
  getCurrentTimeline(): Promise<[number, number?]>;
  getDuration(): number;
  seekTo(timestamp: number): void;
  isPlaying?: boolean;
}

interface IPlayerInfo {
  playerType: 'progressive' | 'hls';
  src: string;
  subtitles: IPlayerSubtitle[];
  points: IPlayerPoint[];
  duration: number;
  hlsPlayerQueryString?: string;
  options: {
    frameRate: {
      denominator: 1;
      numerator: number;
    };
    dropFrame?: boolean;
  };
  clipId: string;
}

interface IPlaylistPlayerProps {
  height: TSize;
  id?: string;
  clips?: IEpisodeSource[] | ISimpleEntry[];
  activeIndex?: number;
  from?: 'regular' | 'meta';
  showControls?: boolean;
  onActiveChanged?(index?: number, id?: string): void;
  onLoad?(): void;
  onError?(): void;
}

interface ISignedClipData {
  [key: string]: IClipVideoData;
}

const PlaylistPlayer = React.memo(
  React.forwardRef<IPlaylistPlayerRef, IPlaylistPlayerProps>(
    (
      {
        height,
        id = generateRandom(8, 'playlist'),
        clips = [],
        activeIndex = 0,
        from = 'regular',
        onActiveChanged,
        onLoad,
        onError,
        showControls = true,
      }: IPlaylistPlayerProps,
      ref,
    ) => {
      const [players, setPlayers] = React.useState<IPlayerInfo[]>([]);
      const [activePlayer, setActivePlayer] = React.useState<IPlayerInfo>();
      const [activePlayerIndex, setActivePlayerIndex] = React.useState<number>();

      const updatePlayersPromise = React.useRef<Promise<IPlayerInfo[]>>();
      const updatePlayerIndexPromise = React.useRef<Promise<number | undefined>>();

      const playerRef = React.useRef<IPlayerRef>(null);

      const getClipData = (clip: IEpisodeSource | ISimpleEntry) => {
        const clipObj: IClip = from === 'regular' ? (clip as IEpisodeSource).clip : (clip as ISimpleEntry).meta.clip;
        const clipId = getClipId(clipObj);

        return {clipObj, clipId};
      };

      const handleChange = React.useCallback(
        (index: number): void => {
          if (!players || !players[index]) {
            return;
          }

          setActivePlayerIndex(index);
          setActivePlayer(players[index]);

          /** This is a stupid way to make auto-playback reliable */
          times(15, num => setTimeout(() => playerRef.current?.play(), num * 100));
        },
        [players],
      );

      React.useImperativeHandle(ref, () => ({
        getCurrentTimeline: async () => {
          let index = activePlayerIndex;

          if (updatePlayerIndexPromise.current) {
            index = await updatePlayerIndexPromise.current;
          }

          return [+(playerRef.current?.getCurrentTime() || 0), index];
        },
        getCurrentTime: () => [+(playerRef.current?.getCurrentTime() || 0), activePlayerIndex],
        getDuration: () => playerRef.current?.getDuration() || 0,
        pause: () => playerRef.current?.pause(),
        play: () => playerRef.current?.play(),
        playPause: () => playerRef.current?.playPause(),
        seekTo: (time: number) => playerRef.current?.seekTo(time),
        isPlaying: playerRef.current?.isPlaying,
      }));

      const updatePlayers = async () => {
        const updatedPlayers: IPlayerInfo[] = [];
        const resolvedClipSignings: ISignedClipData = {};

        const newClips = filter(clips, (clip: IEpisodeSource | ISimpleEntry) => {
          const {clipId} = getClipData(clip);

          return !players.some(clip => clip.clipId === clipId) && !resolvedClipSignings[clipId];
        });

        const signedClipPromises = uniqBy(
          newClips as (IEpisodeSource | ISimpleEntry)[],
          (clip: IEpisodeSource | ISimpleEntry) => {
            const {clipId} = getClipData(clip);

            return clipId;
          },
        ).map(clip => {
          const {clipId, clipObj} = getClipData(clip);

          return getClipVideo(clipObj).then(clipInfo => (resolvedClipSignings[clipId] = clipInfo));
        });

        await Promise.allSettled(signedClipPromises);

        clips.forEach(clip => {
          const {clipId, clipObj} = getClipData(clip);

          const existing =
            players.find(clip => clip.clipId === clipId) || updatedPlayers.find(clip => clip.clipId === clipId);

          let playerObj: Partial<IPlayerInfo> = {};

          if (existing) {
            playerObj = cloneDeep(existing);
          } else {
            try {
              const clipInfo = resolvedClipSignings[clipId];

              playerObj = {
                clipId,
                duration: clipObj.duration,
                playerType: clipInfo.playerType,
                src: clipInfo.streamUrl!,
                subtitles: clipInfo.cc || [],
                hlsPlayerQueryString: clipInfo.signedQuery,
                options: {
                  dropFrame: clipInfo.dropFrame,
                  frameRate: {
                    denominator: 1,
                    numerator: clipObj.framerate || 30,
                  },
                },
              };
            } catch (e) {}
          }

          playerObj.points = [];

          if (!clipObj.liveBroadcast) {
            (clip.adPods || []).forEach((pod: ISourceAdPod | ISimpleAdPod) =>
              playerObj.points!.push({
                timestamp: pod.startAt,
                type: 'pod',
              }),
            );

            Number.isFinite(clip.inPoint) &&
              playerObj.points.push({
                timestamp: clip.inPoint || 0,
                type: 'in',
              });

            Number.isFinite(clip.outPoint) &&
              playerObj.points.push({
                timestamp: clip.outPoint || 0,
                type: 'out',
              });
          }

          updatedPlayers.push(playerObj as IPlayerInfo);
        });

        setPlayers(updatedPlayers);

        return updatedPlayers;
      };

      const updateActivePlayerIndex = async () => {
        let playersClone = cloneDeep(players);

        if (updatePlayersPromise.current) {
          playersClone = await updatePlayersPromise.current;
        }

        if (Number.isFinite(activeIndex) && activeIndex !== activePlayerIndex && playersClone[activeIndex!]) {
          setActivePlayer(playersClone[activeIndex!]);
          setActivePlayerIndex(activeIndex);

          return activeIndex;
        }

        return activePlayerIndex;
      };

      const onPlaybackEnd = React.useMemo(
        () =>
          debounce(
            async () => {
              const currentIndex = await updatePlayerIndexPromise.current;
              const currentPlayers = await updatePlayersPromise.current;

              const index = Number.isFinite(currentIndex) ? currentIndex! : -1;

              if (index === -1 || !playerRef.current?.isPlaying) {
                return;
              }

              if (currentPlayers && currentPlayers[index + 1]) {
                onActiveChanged && onActiveChanged(index + 1);
                handleChange(index + 1);
              }
            },
            1000,
            {
              leading: true,
              trailing: false,
            },
          ),
        [handleChange, onActiveChanged],
      );

      React.useEffect(() => {
        updatePlayersPromise.current = updatePlayers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [clips]);

      React.useEffect(() => {
        updatePlayerIndexPromise.current = updateActivePlayerIndex();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [activeIndex]);

      const prevDisabled = React.useMemo((): boolean => activePlayerIndex === 0, [activePlayerIndex]);
      const nextDisabled = React.useMemo(
        (): boolean => activePlayerIndex === players.length - 1,
        [activePlayerIndex, players],
      );

      if (!activePlayer) {
        return (
          <Box height={height} id={id} background='black'>
            <Spinner center={true} minHeight={height} size='xlarge' />
          </Box>
        );
      }

      return (
        <Stack>
          <Player
            ref={playerRef}
            id={id}
            height={height}
            license='9CE6F075527140BFF002A0E007D58114U9B800763DCE09ED4B3739AA5CF69B309'
            onEnded={onPlaybackEnd}
            playerType={activePlayer.playerType}
            src={activePlayer.src}
            hlsPlayerQueryString={activePlayer.hlsPlayerQueryString}
            options={activePlayer.options}
            subtitles={activePlayer.subtitles}
            points={activePlayer.points}
            duration={activePlayer.duration}
            onLoad={onLoad}
            onError={onError}
          />
          {showControls && (
            <Box padding='xxxxxsmall' background='black'>
              <Cluster justify='center' space='xxlarge'>
                <Button
                  state={prevDisabled ? 'disabled' : ''}
                  type='primary'
                  ghost={true}
                  icon='chevronleft'
                  onClick={() => handleChange(activePlayerIndex! - 1)}
                >
                  Prev Clip
                </Button>
                <Button
                  state={nextDisabled ? 'disabled' : ''}
                  type='primary'
                  ghost={true}
                  icon='chevronright'
                  iconPosition='right'
                  onClick={() => handleChange(activePlayerIndex! + 1)}
                >
                  Next Clip
                </Button>
              </Cluster>
            </Box>
          )}
        </Stack>
      );
    },
  ),
  () => false,
);

export default PlaylistPlayer;
