import * as React from 'react';
import {useHistory} from 'react-router-dom';

import roleRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';
import {useFindQuery, useDeleteMutation} from 'features/roles/rolesApi';
import {TableActions} from 'components/tableActions';

import {
  Box,
  Button,
  Cluster,
  Cover,
  Heading,
  ITableCol,
  Notification,
  Pagination,
  Paragraph,
  Spinner,
  Table,
  TdLink,
  Template,
} from '@pluto-tv/assemble';

import {IRole} from 'models/roles';
import {useAppPermissions} from 'app/permissions';
import {withThousandsSeparator} from 'utils/thousands-separator';

export default (): JSX.Element => {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const {ableTo, permissions} = useAppPermissions();

  if (!ableTo('ROLE_VIEW')) {
    return <NotAuthorized />;
  }

  const {
    data: roles,
    isFetching,
    refetch,
  } = useFindQuery(
    {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    },
    {refetchOnMountOrArgChange: true},
  );
  const [deleteRole] = useDeleteMutation();

  const handleCreate = () => {
    history.push(roleRoutes.paths.roleEditPage.replace(':id', 'new'));
  };

  const handleEdit = (role: IRole) => {
    history.push(roleRoutes.paths.roleEditPage.replace(':id', role._id));
  };

  const handleDelete = async (role: IRole) => {
    if (!ableTo('ROLE_DELETE')) return;
    await deleteRole(role._id).unwrap();
    refetch();
  };

  return (
    <>
      <Cover scrolling={true} gutter='large' coverTemplateHeight='100%' padding={{mobile: 'medium', wide: 'large'}}>
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>Roles</Heading>
              <Paragraph color='secondary'>{withThousandsSeparator(roles?.metadata.totalCount || 0)} Items</Paragraph>
            </Cluster>
            <Button type='primary' onClick={() => handleCreate()} permission={permissions.ROLE_CREATE}>
              + New Role
            </Button>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
            paddingTop={(roles?.data.length as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX='large'
            fullHeight={true}
          >
            <Table
              id='rolesTable'
              loading={isFetching}
              fixedHeader={true}
              wrapContent={true}
              cols={[
                {
                  label: 'Role Name',
                  transform: row => (
                    <TdLink
                      title={row.name}
                      row={row}
                      url={roleRoutes.paths.roleEditPage.replace(':id', row._id)}
                      onClick={handleEdit}
                    />
                  ),
                },
                {
                  label: 'Description',
                  field: 'description',
                },
                ...(ableTo('ROLE_EDIT') || ableTo('ROLE_DELETE')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            row={row}
                            icons={ableTo('ROLE_EDIT') ? ['edit'] : []}
                            deleteOption={ableTo('ROLE_DELETE')}
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'edit':
                                  handleEdit(row);
                                  break;
                                case 'delete':
                                  handleDelete(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        ),
                      } as ITableCol<IRole>,
                    ]
                  : []),
              ]}
              rows={roles?.data}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Roles</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no roles currently available.</Notification>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            {(roles?.data.length as number) > 0 && (
              <Pagination
                perPage={rowsPerPage as 25 | 50 | 75 | 100}
                currentPage={page}
                total={roles?.metadata.totalCount || 0}
                onPageChange={page => setPage(page)}
                onPerPageChange={perPage => {
                  setRowsPerPage(perPage);
                  setPage(0);
                }}
              />
            )}
          </Cluster>
        </Template>
      </Cover>
    </>
  );
};
