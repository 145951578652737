import {
  Box,
  Button,
  Click,
  Cluster,
  Cover,
  Dialog,
  FormItem,
  Grid,
  Heading,
  ITableCol,
  Icon,
  Notification,
  Pagination,
  Paragraph,
  Select,
  Sidebar,
  Spinner,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  TextInput,
  Textarea,
  Toast,
  hmsToSeconds,
  secondsToHms,
  trimModel,
  useCancellableSummon,
  useValidateForm,
} from '@pluto-tv/assemble';
import {cloneDeep, isArray, orderBy} from 'lodash-es';
import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {useAppPermissions} from 'app/permissions';

import episodeRoutes from 'routes/content.routes';

import CrudError from 'components/crudError';
import NotAuthorized from 'components/notAuthorized';
import SeriesList, {ISeriesSearchParams} from 'components/seriesList';
import {TableActions} from 'components/tableActions';

import {useInsertMutation, useLazyFindQuery} from 'features/episodes/episodesApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';

import {mapPopulatedEpisodeToEpisode} from 'helpers/mapPopulatedEpisode';
import {useUserRegions} from 'helpers/useUserRegions';

import {IEpisode, IEpisodeListResult, IListEpisodeQuery} from 'models/episodes';
import {IListPayload, TSortDirection} from 'models/generic';
import {IUserEpisodeSearch} from 'models/users';

import {useEpisodeListPermissions} from 'views/content/episode/permissions/useEpisodePermissions';
import {episodeCreateValidator} from 'views/content/episode/validators';
import {useSeriesPermissions} from 'views/content/series/permissions/useSeriesPermissions';

import {ISeries, ISeriesListResult} from 'models/series';
import EpisodeSearchBar from './episodeSearchBar';
import {withThousandsSeparator} from 'utils/thousands-separator';

interface ISearchState {
  sortCol: keyof typeof TABLE_COLUMN_NAME;
  sortDir: TSortDirection;
  page: number;
  rowsPerPage: number;
  currentSearch: IUserEpisodeSearch;
}

const TABLE_COLUMN_NAME = {
  'Episode Name': 'name',
  'Series Name': 'seriesName',
  S: 'season',
  E: 'number',
  Published: 'published',
  'Episode Status': 'status',
  Duration: 'duration',
  'Ad Pod Duration': 'adPodsDuration',
  'Active Region': 'activeRegion',
  'Next Air Date': 'nextAired',
  'Last Air Date': 'lastAired',
} as const;

const defaultSearch: IUserEpisodeSearch = {
  name: '',
  model: {},
  sortCol: 'createdAt' as keyof typeof TABLE_COLUMN_NAME,
  sortDir: 'dsc',
};

export interface IEpisodeListProps {
  actionsCol?: boolean;
  checkboxCol?: true | 'multiple';
  showFavoriteSearch?: boolean;
  inModal?: boolean;
  onSelect?: (rows?: IEpisodeListResult | IEpisodeListResult[] | undefined) => void;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  importing?: boolean;
  onSinglePage?: boolean;
  presetSearch?: Partial<IListEpisodeQuery>;
  presetSortCol?: keyof typeof TABLE_COLUMN_NAME;
  presetSortDir?: TSortDirection;
  seriesModel?: Partial<ISeries>;
  isSearchExpanded?: boolean;
}

const searchReducer = (prevState: ISearchState, data: Partial<ISearchState>) => {
  return {
    ...prevState,
    ...data,
  };
};

const EpisodeList = React.memo(
  ({
    actionsCol = true,
    checkboxCol = true,
    inModal = false,
    onSelect,
    showFavoriteSearch = true,
    onSinglePage = false,
    importing = false,
    presetSearch,
    presetSortCol = defaultSearch.sortCol as keyof typeof TABLE_COLUMN_NAME,
    presetSortDir = defaultSearch.sortDir,
    seriesModel,
    isSearchExpanded = false,
  }: IEpisodeListProps) => {
    const history = useHistory();
    const {ableTo} = useAppPermissions();

    // Ref to track mounted state
    const isMountedRef = React.useRef(false);

    // Update the ref on mount/unmount
    React.useEffect(() => {
      isMountedRef.current = true;

      return () => {
        isMountedRef.current = false;
      };
    }, []);

    const {canViewOO, canViewPartner} = useEpisodeListPermissions();
    const {editPermission} = useSeriesPermissions(seriesModel);

    const {
      activeRegions,
      isFetching: isFetchingRegions,
      isError: isErrorRegions,
      isSuccess: isSuccessRegions,
    } = useUserRegions();

    const {
      form,
      model,
      onBlur,
      onChange,
      setFields,
      reset: resetCreate,
      state: formState,
    } = useValidateForm<IEpisode>(episodeCreateValidator);

    const [summon] = useCancellableSummon();

    const [params, setParamsDispatch] = React.useReducer<React.Reducer<ISearchState, Partial<ISearchState>>>(
      searchReducer,
      {
        sortCol: presetSortCol,
        sortDir: presetSortDir,
        page: 0,
        rowsPerPage: 25,
        currentSearch: {
          ...defaultSearch,
          sortCol: presetSortCol,
          sortDir: presetSortDir,
          ...(presetSearch && {
            model: {
              ...presetSearch,
            },
          }),
        },
      },
    );

    const {sortCol, sortDir, page, rowsPerPage, currentSearch} = params;

    const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);

    const [selectedEpisodes, setSelectedEpisodes] = React.useState<IEpisodeListResult[]>([]);
    const [isArchiving, setIsArchiving] = React.useState<boolean>(false);
    const [isCreating, setIsCreating] = React.useState<boolean>(false);

    const [searchEpisodes, episodesResponse] = useLazyFindQuery();
    const {data: languages} = useFindLanguagesQuery();

    const [episodes, setEpisodes] = React.useState<IListPayload<IEpisodeListResult>>();
    const [selectedEpisodeAdded, setSelectedEpisodeAdded] = React.useState<string[]>([]);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isError, setIsError] = React.useState<boolean>();

    const [insertEpisode] = useInsertMutation();

    React.useEffect(() => {
      setEpisodes(episodesResponse?.data);
      setIsError(episodesResponse?.isError);

      if (episodesResponse?.isFetching || episodesResponse?.isLoading || episodesResponse?.isUninitialized) {
        setIsLoading(true);
      } else {
        setTimeout(() => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        }, 100);
      }
    }, [episodesResponse]);

    /*
     * Highlight row when episode is added and remove highlight after 8 seconds
     */
    React.useEffect(() => {
      const highlightRowTimeout = setTimeout(() => {
        if (selectedEpisodeAdded.length) {
          setSelectedEpisodeAdded([]);
        }
      }, 8000);
      return () => clearTimeout(highlightRowTimeout);
    }, [selectedEpisodeAdded]);

    const getRowState = React.useCallback(
      (episodeRow: IEpisodeListResult) => {
        if (selectedEpisodeAdded.length && selectedEpisodeAdded.find(episodeID => episodeID === episodeRow._id)) {
          return 'success';
        }
        return '';
      },
      [selectedEpisodeAdded],
    );

    const getEditUrl = (episode: IEpisodeListResult) => {
      const isSeriesPage = location.pathname.includes('/series/');
      let redirectUrl: string | void = '';

      if (isSeriesPage) {
        redirectUrl = episodeRoutes.paths.seriesEditEpisodesVideoPage
          .replace(':id', episode.seriesID)
          .replace(':episodeId', episode._id);
      } else {
        redirectUrl = onSinglePage
          ? episodeRoutes.paths.seriesEditEpisodesVideoPage
              .replace(':id', episode.seriesID)
              .replace(':episodeId', episode._id || '')
          : episodeRoutes.paths.episodeEditDetailsPage.replace(':id', episode._id);
        // .replace(':episodeId', episode._id || '');
      }
      return redirectUrl;
    };

    const handleEdit = (episode: IEpisodeListResult) => {
      history.push(getEditUrl(episode));
    };

    const handleDelete = async (row: IEpisodeListResult, index: number): Promise<void> => {
      const episode = await summon.get<unknown, IEpisode>(`/episodes/${row._id}`);

      if (episode?.nextTimeline) {
        Toast.error('Please unschedule the episode from any future channel timelines in order to delete.');

        return;
      }

      try {
        await summon.remove(`/episodes/${row._id}`);

        const clonedEpisodes = cloneDeep(episodes);

        if (clonedEpisodes) {
          clonedEpisodes.data.splice(index, 1);
          clonedEpisodes.metadata.totalCount -= 1;
          setEpisodes(clonedEpisodes);
        }

        Toast.success('Success', `Episode: ${row.name} deleted successfully`);
      } catch (e: any) {
        Toast.error(
          'Error',
          e?.data?.message.length > 0
            ? `Error removing episode. Please try again. ${e?.data?.message}`
            : 'Error removing episode. Please try again',
        );
      }
    };

    const episodeSelected = (rows?: IEpisodeListResult | IEpisodeListResult[] | undefined) => {
      if (rows && isArray(rows) && rows.length) {
        setSelectedEpisodes(rows);
      } else {
        setSelectedEpisodes([]);
      }

      onSelect && onSelect(rows);
    };

    const handleCreate = async (navigateTo = false): Promise<string | undefined> => {
      if (isCreating) {
        return;
      }

      let episodeId: string | undefined;

      setIsCreating(true);

      try {
        const postModel: Partial<IEpisode> = {
          ...model,
          description: model.summary,
          published: false,
          status: 'in progress',
          rating: 'Not Rated',
          series: seriesModel?.id as any,
          activeRegion: seriesModel?.activeRegion,
          plutoTvOO: seriesModel?.plutoTvOO,
          genre: seriesModel?.genre,
          subGenre: seriesModel?.subGenre,
          category: seriesModel?.category,
          subCategory: seriesModel?.subCategory,
          distributeAs: {
            AVOD: seriesModel?.distributeAs?.AVOD || false,
            linear: seriesModel?.distributeAs?.linear || false,
          },
          tags: seriesModel?.tags,
        };

        const newEpisode = await insertEpisode(trimModel(postModel, 'name', 'summary', 'description')).unwrap();
        episodeId = newEpisode.id!;

        const toastMsg = navigateTo ? (
          'Episode Created'
        ) : (
          <Stack space='xxsmall'>
            <Paragraph>Episode Created</Paragraph>
            <Click
              underline={true}
              hoverColor='white'
              onClick={() =>
                history.push(
                  episodeRoutes.paths.seriesEditEpisodesDetailsPage
                    .replace(':id', seriesModel!.id!)
                    .replace(':episodeId', episodeId as string),
                )
              }
            >
              View Episode: {postModel.name}
            </Click>
          </Stack>
        );

        if (!navigateTo) {
          let clonedEpisodes = cloneDeep(episodes);

          if (!clonedEpisodes) {
            clonedEpisodes = {
              data: [],
              metadata: {
                limit: rowsPerPage,
                offset: rowsPerPage * page,
                totalCount: 0,
              },
            };
          }

          if (clonedEpisodes && !clonedEpisodes?.data) {
            clonedEpisodes.data = [];
          }

          if (clonedEpisodes && !clonedEpisodes?.metadata) {
            clonedEpisodes.metadata = {
              limit: rowsPerPage,
              offset: rowsPerPage * page,
              totalCount: 0,
            };
          }

          clonedEpisodes.data.unshift({
            ...newEpisode,
            _id: newEpisode.id!,
            seriesID: seriesModel!.id!,
          });

          // Remove last item from page
          if (clonedEpisodes.data.length > rowsPerPage) {
            clonedEpisodes.data.pop();
          }

          clonedEpisodes.metadata.totalCount += 1;

          setEpisodes(clonedEpisodes);
        }

        Toast.success('Success', toastMsg, 8000);
        setIsAddEpisodeOpen(false);
        setSelectedEpisodeAdded([newEpisode.id!]);
      } catch (e) {
        Toast.error('Error', (e as any)?.data?.message);
      }

      setIsCreating(false);

      return episodeId;
    };

    const handleCreateAndEdit = async () => {
      try {
        const episodeId = await handleCreate(true);

        if (episodeId) {
          history.push(
            episodeRoutes.paths.seriesEditEpisodesDetailsPage
              .replace(':id', seriesModel!.id!)
              .replace(':episodeId', episodeId as string),
          );
        }
      } catch (e) {}
    };

    const archiveEpisodes = async () => {
      if (isArchiving) {
        return;
      }

      setIsArchiving(true);

      const getEpisodePromises: Promise<IEpisode>[] = [];
      const putEpisodePromises: Promise<IEpisode>[] = [];

      try {
        selectedEpisodes.forEach(e => {
          if (e.status === 'archived') {
            return;
          }

          getEpisodePromises.push(summon.get<unknown, IEpisode>(`/episodes/${e._id}`));
        });

        const episodeRes = await Promise.all(getEpisodePromises);

        episodeRes.forEach(e => {
          if (e.vodCategoryEntries) {
            Toast.error(
              `Episode ${e.name} is currently programmed in one or more VOD collections. Please remove in order to proceed`,
              `\`${e.vodCategoryEntries.map(vc => vc.vodCategory.name).join('`, `')}\``,
            );
          } else if (e.nextTimeline) {
            Toast.error(
              `Episode ${e.name} contains an upcoming channel timeline airing. Please remove in order to proceed`,
            );
          } else {
            putEpisodePromises.push(
              summon.put(`/episodes/${e.id}`, {
                id: e.id,
                episodeParams: mapPopulatedEpisodeToEpisode({
                  ...e,
                  status: 'archived',
                  published: false,
                }),
                updateFields: ['status', 'published'],
              }),
            );
          }
        });

        const putEpisodeRes = await Promise.all(putEpisodePromises);

        const clonedEpisodes = cloneDeep(episodes);

        putEpisodeRes.forEach(e => {
          Toast.success(`Episode ${e.name} successfully archived`);

          const existingIndex = (clonedEpisodes?.data || []).findIndex(a => a._id === e.id);

          if (existingIndex > -1 && clonedEpisodes?.data && clonedEpisodes.data[existingIndex]) {
            clonedEpisodes.data[existingIndex].status = 'archived';
          }
        });

        if (putEpisodeRes.length) {
          setEpisodes(clonedEpisodes);
        }
      } catch (e) {
        Toast.error('Could not archive episodes. Please try again');
      }

      setSelectedEpisodes([]);
      setIsArchiving(false);
    };

    const [episodesSelectedForImport, setEpisodesSelectedForImport] = React.useState<IEpisodeListResult[]>([]);
    const [isImportingEpisodes, setIsImportingEpisodes] = React.useState<boolean>(false);

    const [seriesSearchParams, setSeriesSearchParams] = React.useState<ISeriesSearchParams | undefined>({
      isSearchExpanded: true,
    } as ISeriesSearchParams);

    const handleEpisodesImport = async () => {
      try {
        setIsImportingEpisodes(true);

        if (episodesSelectedForImport && isArray(episodesSelectedForImport) && episodesSelectedForImport.length) {
          const importedEpisodes = await Promise.all(episodesSelectedForImport.map(duplicateEpisode));

          const newEpisodesResult = {
            ...episodes,
            data: [...(importedEpisodes || []), ...(episodes?.data || [])],
          } as IListPayload<IEpisodeListResult>;

          setEpisodes(newEpisodesResult);
          setEpisodesSelectedForImport([]);
          setIsImportEpisodeOpen(false);
          setSelectedSeries(undefined);
          setSeriesSelector(true);
          setSeriesSearchParams({
            isSearchExpanded: true,
          } as ISeriesSearchParams);
          Toast.success('Episode(s) Successfully Imported');
          // add the id of the imported episode to the selectedEpisodeAdded array
          setSelectedEpisodeAdded([...importedEpisodes.map(episode => episode._id)]);
        }
      } catch (e) {
        Toast.error('Error importing the selected episode due to no clips available. Please try again.');
      } finally {
        setIsImportingEpisodes(false);
      }
    };

    const duplicateEpisode = async (row: IEpisodeListResult): Promise<IEpisodeListResult> => {
      const res = await summon.post<unknown, {episodeID: string}>(`/episodes/${row._id}/duplicate`, {
        id: row._id,
        seriesID: seriesModel?.id,
        seriesActiveRegion: seriesModel?.activeRegion,
      });

      if (!res || !res.episodeID) {
        throw new Error('Error duplicating episode');
      }

      const newEpisode = await summon.get<unknown, IEpisode>(`/episodes/${res.episodeID}`);

      const transformedEpisode: IEpisodeListResult = {
        ...newEpisode,
        _id: newEpisode.id!,
        seriesID: seriesModel!.id!,
      };

      return transformedEpisode;
    };

    const handleDuplicate = async (row: IEpisodeListResult): Promise<void> => {
      try {
        const transformedEpisode = await duplicateEpisode(row);

        const newEpisodes = cloneDeep(episodes);

        if (newEpisodes) {
          newEpisodes.data = [transformedEpisode, ...newEpisodes.data];
          setEpisodes(newEpisodes);
          // add the id of the imported episode to the selectedEpisodeAdded array
          setSelectedEpisodeAdded([transformedEpisode._id]);
        }
      } catch (e) {
        Toast.error('Error duplicating episode. Please try again');
      }
    };

    const [isImportEpisodeOpen, setIsImportEpisodeOpen] = React.useState(false);
    const [isAddEpisodeOpen, setIsAddEpisodeOpen] = React.useState(false);

    const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
      const newSortDir: TSortDirection = columnName === sortCol ? (sortDir === 'asc' ? 'dsc' : 'asc') : 'dsc';
      setParamsDispatch({
        sortCol: columnName,
        sortDir: newSortDir,
        page: 0,
        currentSearch: {
          ...currentSearch,
          sortCol: columnName,
          sortDir: newSortDir,
        },
      });
    };

    const handleClear = React.useCallback(() => {
      const initialSearch: IUserEpisodeSearch = {
        ...defaultSearch,
        sortCol: presetSortCol,
        sortDir: presetSortDir,
        ...(presetSearch && {
          model: {
            ...presetSearch,
          },
        }),
      };

      setParamsDispatch({
        page: 0,
        rowsPerPage: 25,
        sortCol: initialSearch.sortCol as keyof typeof TABLE_COLUMN_NAME,
        sortDir: initialSearch.sortDir,
        currentSearch: initialSearch,
      });
    }, [presetSearch, presetSortCol, presetSortDir]);

    const handleSearch = React.useCallback((searchParams: IUserEpisodeSearch) => {
      setParamsDispatch({
        currentSearch: searchParams,
        page: 0,
      });
    }, []);

    const performSearch = (searchParams: IUserEpisodeSearch) => {
      searchEpisodes({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        sort: (TABLE_COLUMN_NAME[searchParams.sortCol] || presetSortCol) + ':' + searchParams.sortDir,
        ...searchParams.model,
      });
    };

    const transformSearch = () => {
      if (currentSearch && !isFetchingRegions && !isErrorRegions) {
        performSearch({
          ...currentSearch,
          model: {
            ...currentSearch.model,
            activeRegions:
              (currentSearch.model.activeRegions || []).length === 0
                ? activeRegions.map(ar => ar.code)
                : currentSearch.model.activeRegions,
            ...(!canViewOO &&
              canViewPartner && {
                plutoTvOO: false,
              }),
            ...(canViewOO &&
              !canViewPartner && {
                plutoTvOO: true,
              }),
          },
        });
      }
    };

    const [selectedSeries, setSelectedSeries] = React.useState<ISeriesListResult | undefined>(undefined);
    const [seriesSelector, setSeriesSelector] = React.useState(true);
    const toggleSeriesSelector = () => {
      setSeriesSelector(!seriesSelector);
    };

    React.useEffect(() => {
      if (isSuccessRegions) {
        transformSearch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingRegions, isErrorRegions, currentSearch, activeRegions, page, rowsPerPage]);

    if (!ableTo('EPISODE_VIEW') && !ableTo('SERIES_VIEW')) {
      return <NotAuthorized />;
    }

    if (isError) {
      return <CrudError error='Error loading page data' />;
    }

    return (
      <Sidebar fullHeight={true}>
        {!isImportEpisodeOpen && !isAddEpisodeOpen && (
          <EpisodeSearchBar
            sortDir={sortDir}
            sortCol={sortCol}
            inModal={inModal}
            isFetching={isLoading}
            isSearchExpanded={searchExpanded}
            onSinglePage={onSinglePage}
            importing={importing}
            setIsSearchExpanded={setSearchExpanded}
            showFavoriteSearch={showFavoriteSearch}
            search={currentSearch}
            onSearch={handleSearch}
            onClear={handleClear}
            canViewOO={canViewOO}
            canViewPartner={canViewPartner}
          />
        )}
        <Cover
          scrolling={true}
          gutterTop={onSinglePage ? 'none' : 'large'}
          gutterBottom='large'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={inModal || onSinglePage ? 'none' : {mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            {!onSinglePage && !importing && (
              <Cluster justify='space-between' align='center' space='medium'>
                <Cluster align='end' space='small'>
                  <Heading level='h1'>Episodes</Heading>
                  <Cluster space='xxsmall' align='center'>
                    <Icon
                      icon='tune'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={() => setSearchExpanded(!searchExpanded)}
                    >
                      {withThousandsSeparator(episodes?.metadata.totalCount || 0)} Items
                    </Icon>
                  </Cluster>
                </Cluster>
                <Box height='1.7rem'></Box>
              </Cluster>
            )}
            {onSinglePage && (
              <Box
                id='episodesTotalCount'
                background='pewter'
                borderTop={true}
                borderSize='0.125rem'
                borderColor='cavern'
                paddingTop={inModal ? 'none' : 'small'}
                paddingBottom='small'
                paddingX={inModal ? 'none' : 'xlarge'}
              >
                <Cluster justify='space-between' align='center' space='medium'>
                  <Cluster align='end' space='small'>
                    <Heading level='h3' color='secondary'>
                      Episodes
                    </Heading>
                    <Cluster space='xxsmall' align='center'>
                      <Icon
                        icon='tune'
                        space='xxxsmall'
                        verticalAlign='bottom'
                        lineHeight='0px'
                        onClick={() => setSearchExpanded(!searchExpanded)}
                      >
                        {withThousandsSeparator(episodes?.metadata.totalCount || 0)} Items
                      </Icon>
                    </Cluster>
                  </Cluster>
                  <Cluster space='small'>
                    <Button
                      permission={ableTo('EPISODE_EDIT') ? '' : 'disabled'}
                      state={selectedEpisodes.length === 0 ? 'disabled' : isArchiving ? 'thinking' : ''}
                      onClick={archiveEpisodes}
                      id='archiveButton'
                    >
                      Archive
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => {
                        setIsImportEpisodeOpen(true);
                        setSearchExpanded(false);
                      }}
                      permission={editPermission}
                    >
                      Import Episode
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => {
                        resetCreate();
                        setIsAddEpisodeOpen(true);
                        setSearchExpanded(false);
                      }}
                      permission={ableTo('EPISODE_CREATE') ? '' : 'disabled'}
                    >
                      + Add Episode
                    </Button>
                  </Cluster>
                </Cluster>
                <Dialog
                  isOpen={isAddEpisodeOpen}
                  onClose={() => setIsAddEpisodeOpen(false)}
                  width='42.8125rem'
                  height='35.2rem'
                  id='addEpisodeModal'
                >
                  <Template label='header'>
                    <Heading level='h2'>Create Episode</Heading>
                  </Template>
                  <Template label='body'>
                    <Stack space='medium'>
                      <FormItem {...form.name} onBlur={() => onBlur('name')}>
                        <TextInput onChange={value => onChange('name', value)} id='title' placeholder='Episode Name' />
                      </FormItem>
                      <Grid gap='medium'>
                        <FormItem {...form.season} onBlur={() => onBlur('season', false)}>
                          <Select
                            onChange={value => setFields({season: +value.label})}
                            value={{label: `${model.season}` || ''}}
                            id='season'
                            placeholder='Season Number'
                            options={(seriesModel?.seasons || []).map(s => ({label: `${s.number}`}))}
                          />
                        </FormItem>
                        <FormItem {...form.number} onBlur={() => onBlur('number')}>
                          <TextInput
                            type='number'
                            onChange={value => onChange('number', value)}
                            id='number'
                            placeholder='Episode Number'
                          />
                        </FormItem>
                        <FormItem {...form.metadataLanguage} onBlur={() => onBlur('metadataLanguage', false)}>
                          <Select
                            onChange={value => setFields({metadataLanguage: value.value})}
                            id='metadataLanguage'
                            value={{label: model.metadataLanguage || '', value: model.metadataLanguage}}
                            predicate='value'
                            placeholder='Please select metadata language'
                            searchable={true}
                            appendToBody={true}
                            searchPlaceholder='Search for language'
                            onSearch={val =>
                              orderBy(
                                (languages || [])
                                  .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                                  .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                              ) || []
                            }
                            options={orderBy(
                              (languages || []).map(language => ({
                                label: language.name,
                                value: language.iso639_1,
                              })),
                              'label',
                            )}
                          />
                        </FormItem>
                        <FormItem {...form.allotment} onBlur={() => onBlur('allotment')}>
                          <TextInput
                            onChange={value => onChange('allotment', hmsToSeconds(value))}
                            fixedPlaceholder='HH:MM:SS'
                            mask='NN:[0-5]N:[0-5]N'
                            id='allotment'
                          />
                        </FormItem>
                      </Grid>
                      <FormItem {...form.summary} onBlur={() => onBlur('summary')}>
                        <Textarea
                          onChange={value => onChange('summary', value)}
                          id='summary'
                          minHeight='6.25rem'
                          placeholder='Short Description'
                        />
                      </FormItem>
                    </Stack>
                  </Template>
                  <Template label='footer'>
                    <Cluster justify='space-between'>
                      <div></div>
                      <Cluster space='small'>
                        <Button ghost={true} onClick={() => setIsAddEpisodeOpen(false)} id='cancelButton'>
                          Cancel
                        </Button>
                        <Button
                          type='primary'
                          state={!formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''}
                          onClick={() => handleCreate()}
                          id='createButton'
                        >
                          Create
                        </Button>
                        <Button
                          type='primary'
                          state={!formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''}
                          onClick={() => handleCreateAndEdit()}
                          id='createEditButton'
                        >
                          Create and Edit
                        </Button>
                      </Cluster>
                    </Cluster>
                  </Template>
                </Dialog>
                {isImportEpisodeOpen && (
                  <Dialog
                    id='importEpisodeModal'
                    isOpen={isImportEpisodeOpen}
                    onClose={() => {
                      setSeriesSelector(true);
                      setIsImportEpisodeOpen(false);
                      setSeriesSearchParams({
                        isSearchExpanded: true,
                      } as ISeriesSearchParams);
                      setSelectedSeries(undefined);
                    }}
                    width='100%'
                    height='100%'
                  >
                    <Template label='header'>
                      <Heading level='h2'>Import an Episode</Heading>
                    </Template>
                    <Template label='body'>
                      {seriesSelector ? (
                        <SeriesList
                          actionsCol={false}
                          addNewSeries={false}
                          showFavoriteSearch={false}
                          checkboxCol={true}
                          inModal={true}
                          type='importing'
                          isSearchExpanded={true}
                          onSelect={rows => setSelectedSeries(Array.isArray(rows) ? rows[0] : rows)}
                          selectedSeries={selectedSeries}
                          seriesSearchParams={seriesSearchParams}
                          setSeriesSearchParams={setSeriesSearchParams}
                        />
                      ) : (
                        <EpisodeList
                          actionsCol={false}
                          showFavoriteSearch={false}
                          checkboxCol='multiple'
                          isSearchExpanded={true}
                          importing={true}
                          inModal={true}
                          presetSearch={{
                            seriesID: selectedSeries?._id,
                            hasValidClips: true,
                          }}
                          presetSortCol='S'
                          presetSortDir='asc'
                          onSelect={(rows: IEpisodeListResult | IEpisodeListResult[] | undefined) =>
                            setEpisodesSelectedForImport(rows as IEpisodeListResult[])
                          }
                        />
                      )}
                    </Template>
                    <Template label='footer'>
                      <Cluster justify='space-between'>
                        {seriesSelector ? (
                          <div></div>
                        ) : (
                          <Button
                            type='secondary'
                            state={isImportingEpisodes ? 'disabled' : 'normal'}
                            icon='chevronleft'
                            onClick={() => toggleSeriesSelector()}
                          >
                            Back
                          </Button>
                        )}
                        <Cluster space='small'>
                          <Button
                            ghost={true}
                            state={isImportingEpisodes ? 'disabled' : 'normal'}
                            onClick={() => {
                              setSeriesSelector(true);
                              setIsImportEpisodeOpen(false);
                              setSeriesSearchParams({
                                isSearchExpanded: true,
                              } as ISeriesSearchParams);
                              setSelectedSeries(undefined);
                            }}
                          >
                            Cancel
                          </Button>
                          {seriesSelector ? (
                            <Button
                              type='primary'
                              onClick={() => toggleSeriesSelector()}
                              state={!!selectedSeries ? 'normal' : 'disabled'}
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              type='primary'
                              onClick={handleEpisodesImport}
                              state={isImportingEpisodes ? 'thinking' : 'normal'}
                            >
                              Import
                            </Button>
                          )}
                        </Cluster>
                      </Cluster>
                    </Template>
                  </Dialog>
                )}
              </Box>
            )}
            {importing && (
              <Cluster justify='space-between' align='center' space='medium'>
                <Cluster align='end' space='small'>
                  <Heading level='h3' color='secondary'>
                    Step 2: Select an Episode
                  </Heading>
                  <Cluster space='xxsmall' align='center'>
                    <Icon
                      icon='tune'
                      // color='textSecondary'
                      // size='large'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={() => setSearchExpanded(!searchExpanded)}
                    >
                      {withThousandsSeparator(episodes?.metadata.totalCount || 0)} Items
                    </Icon>
                  </Cluster>
                </Cluster>
              </Cluster>
            )}
          </Template>
          <Template label='cover'>
            <Box
              background='pewter'
              borderTop={onSinglePage ? false : true}
              borderSize='0.125rem'
              borderColor='cavern'
              paddingTop={
                inModal || onSinglePage ? 'none' : ((episodes?.data || []).length as number) > 0 ? 'xsmall' : 'medium'
              }
              paddingBottom='none'
              paddingX={inModal ? 'none' : 'large'}
              fullHeight={true}
            >
              <Table<IEpisodeListResult>
                predicate='_id'
                loading={isLoading}
                fixedHeader={true}
                wrapContent={true}
                rowStatus={row => getRowState(row) as any}
                onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
                onSelect={episodeSelected}
                selected={selectedEpisodes}
                selectable={checkboxCol as 'multiple'}
                sortDir={sortDir}
                sortCol={sortCol}
                emptyMsg='No Episodes'
                id='episodes-search-table'
                cols={[
                  {
                    label: 'Episode Name',
                    sortable: true,
                    colMinWidth: '15.625rem',
                    transform: row =>
                      !importing ? (
                        <TdLink row={row} title={row.name} url={getEditUrl(row)} onClick={handleEdit} />
                      ) : (
                        row.name
                      ),
                  },
                  ...(!onSinglePage
                    ? [
                        {
                          label: 'Series Name',
                          sortable: false,
                          colMinWidth: '15.625rem',
                          transform: row =>
                            !importing ? (
                              <TdLink
                                row={row}
                                title={row.seriesName || ''}
                                url={`/series/${row.seriesID}`}
                                onClick={handleEdit}
                              />
                            ) : (
                              row.seriesName
                            ),
                        } as ITableCol<IEpisodeListResult>,
                      ]
                    : []),
                  ...(!onSinglePage
                    ? [
                        {
                          label: 'Active Region',
                          sortable: true,
                          colMinWidth: '9.375rem',
                          transform: row => row.activeRegion.toUpperCase(),
                        } as ITableCol<IEpisodeListResult>,
                      ]
                    : []),
                  {
                    label: 'S',
                    sortable: true,
                    colMinWidth: '3.125rem',
                    field: 'season',
                  },
                  {
                    label: 'E',
                    sortable: true,
                    colMinWidth: '3.125rem',
                    field: 'number',
                  },
                  ...(!onSinglePage
                    ? [
                        {
                          label: 'Published',
                          sortable: true,
                          colMinWidth: '6.875rem',
                          transform: row => (
                            <Status
                              label={row.published ? 'Published' : 'Unpublished'}
                              state={row.published ? 'success' : 'neutral'}
                            />
                          ),
                        } as ITableCol<IEpisodeListResult>,
                      ]
                    : []),
                  {
                    label: 'Episode Status',
                    sortable: true,
                    colMinWidth: '10rem',
                    transform: row =>
                      row.status === 'in progress' || row.status === 'on air' ? (
                        <Icon
                          icon={row.status === 'in progress' ? 'inprogress' : 'onair'}
                          color={row.status === 'in progress' ? 'infoLight' : 'warning'}
                          pulse={row.status === 'in progress' ? true : false}
                          space='xxxsmall'
                        >
                          {row.status === 'in progress' ? 'In Progress' : 'On Air'}
                        </Icon>
                      ) : (
                        <Icon
                          space='xxxsmall'
                          icon={row.status === 'completed' ? 'check' : 'archive'}
                          color={row.status === 'completed' ? 'success' : 'neutral'}
                        >
                          {row.status === 'completed' ? 'Completed' : 'Archived'}
                        </Icon>
                      ),
                  },
                  ...(onSinglePage
                    ? [
                        {
                          label: 'Meta',
                          colMinWidth: '6.5rem',
                          transform: row => {
                            const hasMetaData = row.category && row.subCategory && row.genre && row.subGenre;

                            return (
                              <Icon
                                icon={hasMetaData ? 'check' : 'warning'}
                                color={hasMetaData ? 'success' : 'warning'}
                                space='xxxsmall'
                              >
                                {hasMetaData ? 'Yes' : 'Warning'}
                              </Icon>
                            );
                          },
                        } as ITableCol<IEpisodeListResult>,
                        {
                          label: 'Artwork',
                          colMinWidth: '5.5rem',
                          transform: row => {
                            const hasArtwork =
                              row?.poster?.path &&
                              row?.poster?.path.indexOf('images/default') === -1 &&
                              row?.screenshot16_9?.path &&
                              row?.screenshot16_9?.path.indexOf('images/default') === -1 &&
                              row?.screenshot4_3?.path &&
                              row?.screenshot4_3?.path.indexOf('images/default') === -1;

                            return hasArtwork ? (
                              <Icon icon='check' color='success' space='xxxsmall'>
                                Yes
                              </Icon>
                            ) : (
                              <Icon icon='warning' color='warning' space='xxxsmall'>
                                No
                              </Icon>
                            );
                          },
                        } as ITableCol<IEpisodeListResult>,
                      ]
                    : []),
                  {
                    label: 'Next Air Date',
                    sortable: true,
                    colMinWidth: '9.375rem',
                    transform: row => (row.nextAired ? new Date(row.nextAired).toLocaleDateString() : ''),
                  },
                  {
                    label: 'Last Air Date',
                    sortable: true,
                    colMinWidth: '9.375rem',
                    transform: row => (row.lastAired ? new Date(row.lastAired).toLocaleDateString() : ''),
                  } as ITableCol<IEpisodeListResult>,
                  {
                    label: 'Duration',
                    sortable: true,
                    colMinWidth: '7.5rem',
                    transform: row => secondsToHms(row.duration),
                  },
                  ...(!onSinglePage
                    ? [
                        {
                          label: 'Ad Pod Duration',
                          sortable: true,
                          colMinWidth: '10.625rem',
                          transform: row => secondsToHms(row.adPodsDuration || 0),
                        } as ITableCol<IEpisodeListResult>,
                      ]
                    : []),
                  ...((ableTo('EPISODE_EDIT') || ableTo('EPISODE_DELETE')) && actionsCol
                    ? [
                        {
                          label: 'Actions',
                          colMinWidth: '6rem',
                          transform: (row, _col, index) => (
                            <TableActions
                              row={row}
                              icons={ableTo('EPISODE_EDIT') ? ['edit'] : []}
                              deleteOption={onSinglePage && ableTo('EPISODE_DELETE')}
                              duplicateOption={onSinglePage && ableTo('EPISODE_CREATE')}
                              onClick={(row, icon) => {
                                switch (icon) {
                                  case 'edit':
                                    handleEdit(row);
                                    break;
                                  case 'copy':
                                    handleDuplicate(row);
                                    break;
                                  case 'delete':
                                    handleDelete(row, index);
                                    break;
                                  default:
                                }
                              }}
                            />
                          ),
                        } as ITableCol<IEpisodeListResult>,
                      ]
                    : []),
                ]}
                rows={episodes?.data || []}
              >
                <Template label='loading'>
                  <Cluster space='small' align='center'>
                    <Spinner />
                    <Paragraph>Loading Episodes</Paragraph>
                  </Cluster>
                </Template>
                <Template label='empty'>
                  <Notification type='warning'>There are no episodes currently available.</Notification>
                </Template>
              </Table>
            </Box>
          </Template>
          <Template label='footer'>
            <Cluster justify='space-between'>
              <div></div>
              {(episodes?.data || []).length > 0 && (
                <Pagination
                  id='pagination'
                  perPage={rowsPerPage as 25 | 50 | 75 | 100}
                  currentPage={page}
                  total={episodes?.metadata.totalCount || 0}
                  onPageChange={page => {
                    setParamsDispatch({
                      page,
                    });
                    document.getElementById('episodes-search-table')?.parentElement?.scrollTo(0, 0);
                  }}
                  onPerPageChange={perPage => {
                    setParamsDispatch({
                      rowsPerPage: perPage,
                      page: 0,
                    });
                  }}
                />
              )}
            </Cluster>
          </Template>
        </Cover>
      </Sidebar>
    );
  },
);

EpisodeList.displayName = 'EpisodeList';
export default EpisodeList;
