import * as React from 'react';
import {useHistory} from 'react-router-dom';

import activeRegionsRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';
import {useFindQuery as useFindRegionsQuery, useDeleteMutation} from 'features/activeRegions/activeRegionsApi';
import {TableActions} from 'components/tableActions';

import {
  Box,
  Button,
  Cluster,
  Cover,
  Heading,
  ITableCol,
  Notification,
  Pagination,
  Paragraph,
  Pill,
  Spinner,
  Table,
  TdLink,
  Template,
  TIcons,
  Toast,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';
import {IActiveRegion} from 'models/activeRegions';
import {withThousandsSeparator} from 'utils/thousands-separator';

const TdPills = ({row}) =>
  row && row.territories && row.territories.length > 0 ? (
    <Cluster space='xsmall'>
      {row.territories.map(territory => (
        <Pill key={territory} label={territory}></Pill>
      ))}
    </Cluster>
  ) : (
    <>No territories</>
  );

export default (): JSX.Element => {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const {ableTo, permissions} = useAppPermissions();
  const [removeRegion] = useDeleteMutation();

  if (!ableTo('ACTIVE_REGION_VIEW')) {
    return <NotAuthorized />;
  }

  const {data: activeRegions, isFetching} = useFindRegionsQuery({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    sort: 'name:asc',
  });

  const handleCreate = () => {
    history.push(activeRegionsRoutes.paths.activeRegionsEditPage.replace(':id', 'new'));
  };

  const handleEdit = async (activeRegion: IActiveRegion, icon: TIcons) => {
    if (icon === 'edit') {
      history.push(activeRegionsRoutes.paths.activeRegionsEditPage.replace(':id', activeRegion._id));
    } else if (icon === 'delete') {
      try {
        await removeRegion(activeRegion._id);
      } catch (e) {
        Toast.error('Error', `Failed to remove ${activeRegion.name} region`);
      }
    }
  };

  return (
    <>
      <Cover scrolling={true} gutter='large' coverTemplateHeight='100%' padding={{mobile: 'medium', wide: 'large'}}>
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>Active Regions</Heading>
              <Paragraph color='secondary'>
                {withThousandsSeparator(activeRegions?.metadata.totalCount || 0)} Items
              </Paragraph>
            </Cluster>
            <Cluster space='small'>
              <Button type='primary' onClick={() => handleCreate()} permission={permissions.ACTIVE_REGION_CREATE}>
                + New Active Region
              </Button>
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
            paddingTop={(activeRegions?.data.length as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX='large'
            fullHeight={true}
          >
            <Table
              loading={isFetching}
              fixedHeader={true}
              cols={[
                {
                  label: 'Region Name',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.name}
                      url={activeRegionsRoutes.paths.activeRegionsEditPage.replace(':id', row._id)}
                      onClick={row => handleEdit(row, 'edit')}
                    />
                  ),
                },
                {
                  label: 'Region Slug',
                  field: 'code',
                },
                {
                  label: 'Associated Territories',
                  transform: row => <TdPills row={row} />,
                },
                ...(ableTo('ACTIVE_REGION_EDIT') || ableTo('ACTIVE_REGION_DELETE')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            icons={ableTo('ACTIVE_REGION_EDIT') ? ['edit'] : []}
                            deleteOption={ableTo('ACTIVE_REGION_DELETE')}
                            row={row}
                            onClick={(row, icon) => handleEdit(row, icon)}
                          />
                        ),
                      } as ITableCol<IActiveRegion>,
                    ]
                  : []),
              ]}
              rows={activeRegions?.data}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Users</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no users currently available.</Notification>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            {(activeRegions?.data.length as number) > 0 && (
              <Pagination
                perPage={rowsPerPage as 25 | 50 | 75 | 100}
                currentPage={page}
                total={activeRegions?.metadata.totalCount || 0}
                onPageChange={page => setPage(page)}
                onPerPageChange={perPage => {
                  setRowsPerPage(perPage);
                  setPage(0);
                }}
              />
            )}
          </Cluster>
        </Template>
      </Cover>
    </>
  );
};
